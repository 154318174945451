<template>
  <div>
    <div
      class="mb-4"
      v-if="targetsText"
    >
      <div
        class="font-weight-bold subtitle-1 mb-2"
      >
        <div>{{'batch.helper'| t}}</div>
        <div
          v-if="hasAnyDisabledTargets"
          class="mt-2"
        >
          {{'batch.helper.disabled'| t}}
        </div>
      </div>
      <div v-html="targetsText"></div>
    </div>

    <div
      v-if="!hasAnyTargets"
      class="font-weight-bold orange--text"
    >
      {{'batch.none'| t}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    popupConfig: Object,
  },
  computed: {
    hasAnyTargets() {
      if(!Array.isArray(this.targets)) return false
      return this.targets.length > 0
    },
    hasAnyDisabledTargets() {
      return this.popupConfig.hasAnyDisabledTargets
    },
    targets() {
      return this.popupConfig.targets
    },
    targetsText() {
      return this.popupConfig.targetsText
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>